<template>
  <div class="container font14" v-loading="loading">
    <!-- 占位div -->
    <div :style="{height: $store.state.changeHeight + 'px'}"></div>
    <div class="main flex-space-between">
      <!-- 主体内容 -->
      <div class="main_left" style="flex:1;">
        <p
          class="blueFont font_weight_700"
          style="cursor: pointer;margin-top:24px;font-size:12px;"
          @click="$router.push('/shopCart')"
        >
          <i class="el-icon-arrow-left" />
          <span class="font_weight_700" style="font-size:12px;vertical-align:top;">Back</span>
        </p>
        <h2 class="boldFont mt15 font24">
          CheckOut
          <!-- <span style="font-weight:100;margin-left:1rem">{{countDownStr}}</span> -->
        </h2>
        <div class="base grayBackground flex-space-between">
          <span>
            <i class="el-icon-check boldFont" />1.Contact
          </span>
          <span @click="$router.push('/checkOut')" class="blueFont edit">Edit</span>
        </div>
        <div class="peopleInfoForm">
          <p>
            <span class="mr5">First Name:</span>
            <span>{{ form.firstName }}</span>
          </p>
          <p>
            <span class="mr5">Last Name:</span>
            <span>{{ form.lastName }}</span>
          </p>
          <p>
            <span class="mr5">Phone Number:</span>
            <span>{{ form.areaCode + form.mobile }}</span>
          </p>
          <p>
            <span class="mr5">Nationality:</span>
            <span>{{ nationality }}</span>
          </p>
          <p>
            <span class="mr5">Place of Residence:</span>
            <span>{{ country }}</span>
          </p>
          <p v-if="form.area !== ''">
            <span class="mr5">Prefecture:</span>
            <span>{{ area }}</span>
          </p>
          <p>
            <span class="mr5">Address:</span>
            <span>{{ form.address }}</span>
          </p>
          <!-- <p>
            <span>Zip/Postal Code:</span>
            <span>{{ form.code }}</span>
          </p>-->
          <p>
            <span class="mr5">Email Address:</span>
            <span>{{ form.email }}</span>
          </p>
        </div>
        <div class="base mt15 boldFont">2. Review</div>
        <form v-if="stripeInpShow" id="payment-form" class="form mt15">
          <div id="card-element" class="inp"></div>
          <div id="card-errors" role="alert"></div>
        </form>
        <p class="mt15" style="line-height: 20px;">
          <span style="vertical-align: top;">Please double check your order details, then pay your order within</span>
          <img style="height: 20px;margin: auto 2px;" src="../../assets/timepiece.png" mode="heightFix" />
          <span style=" color: red;font-size: 20px;font-weight: 700;vertical-align: top;line-height: 1.1;">{{ countDownStr }}</span>
        </p>
        <!-- <p>To checkout with Apple Pay, please use devices compatible with Apple Pay: iPhone, iPad, and Mac.</p> -->
        <br />
        <!-- <p>Please note that if the above information is incomplete or incorrect, we reserve the right to cancel the order.</p> -->
        <!-- <p style="font-weight:500;display: flex;align-items: flex-start;"><img style="height:14px;margin-right: 4px;margin-top: 3px;" src="https://qncdn.tripodeck.com/10001/upload/img/20211116082944205" alt=""> Notice: We apologize that only Apple Pay is currently available until further notice.</p> -->
        <div>
          <!-- <el-radio-group v-model="payType">
            <el-radio :label="3">Stripe</el-radio>
            <el-radio :label="7">Paypay</el-radio>
          </el-radio-group>-->
        </div>
        <!-- <p>Please select the payment method:</p> -->

        <!-- 下面用于调出vt支付弹框的按钮 -->
        <el-button id="payBtn" style="visibility: hidden;" />
        <!-- <el-button v-if="!stripeInpShow" @click="toPay()" class="mt15 pay-btn" type="success">PAY ORDER</el-button> -->
        <el-button v-if="stripeInpShow" class="mt15 pay-btn" type="success">PAY ORDER</el-button>
        <div style="display:flex;align-items:center">
          <!-- 隐藏按钮 -->
          <!-- <el-button v-if="!stripeInpShow" id="paySubmit" @click="apay(9)" :disabled="payBtnDisable" class="mt15 pay-btn" type="success">PAY ORDER</el-button> -->
          <!-- 之前的TV按钮 -->
           <!-- && $route.query.a == 'kjashydiashdiuahwiugdiawjhdklahsdiagwd' -->
          <el-button v-if="!stripeInpShow && !isPhone"  id="paySubmit" @click="apay(15)" :disabled="payBtnDisable" class="mt15 pay-btn" type="success">Alipay+</el-button>
          <el-button v-if="!stripeInpShow && isPhone" id="paySubmit" @click="apay(16)" :disabled="payBtnDisable" class="mt15 pay-btn" type="success">Alipay+</el-button>
          <el-button v-if="!stripeInpShow" id="paySubmit" @click="apay(13)" :disabled="payBtnDisable" class="mt15 pay-btn" type="success">Credit Card</el-button>
          <!--现在的mdk按钮 -->
          <!-- <el-button v-if="!stripeInpShow " id="paySubmit" @click="apay(14)" :disabled="payBtnDisable" class="mt15 pay-btn" type="success">Credit Card</el-button> -->
          <!-- <el-button
            v-if="!stripeInpShow"
            id="paySubmit"
            @click="apay(13)"
            :disabled="payBtnDisable"
            class="mt15 pay-btn"
            type="success"
          >PAY ORDER</el-button> -->
          <!-- 显示假按钮 -->
          <!-- <el-button style="background-color: #fe6c6f;border-color:#fe6c6f" v-if="!stripeInpShow" id="paySubmit" :disabled="true" class="mt15 pay-btn jiaBtn" type="success">⚠ APPLE PAY only</el-button> -->
          <!-- <div v-if="applePayButton">

            <apple-pay-button
            buttonstyle="black"
            v-if="showApplePay"
            type="plain"
            locale="zh-CN"
            @click="onApplePayButtonClickedJapan"
          ></apple-pay-button>
          </div>
          <div v-else>
            <apple-pay-button
            buttonstyle="black"
            v-if="showApplePay"
            type="plain"
            locale="zh-CN"
            @click="applepayTimeout"
          ></apple-pay-button>
          </div>
          <div v-if="$route.query.a == 'gmoApplePay'">       
              gmo苹果支付
            <apple-pay-button
            buttonstyle="black"
            v-if="showApplePay"
            type="plain"
            locale="zh-CN"
            @click="onApplePayButtonClickedJapanGMO"
          ></apple-pay-button>
          </div> -->
        </div>
      </div>
      <!-- 侧边栏 -->
      <div id="aside" class="aside aside-fixed">
        <el-card class="box-card">
          <div slot="header" class="clearfix boldFont font16">Order Summary</div>
          <div class="card-body">
            <div v-if="ticketsCopy1.length > 0" class="card-body-title">TICKETS</div>
            <div class="ticket-list">
              <div
                v-for="(item, index) in ticketsCopy1"
                :key="index"
                class="ticket-item flex-space-between"
              >
                <div class="ticket-item-left">
                  <p class="ticketName">{{ getTicketTypeName(item.ticketKind) }}</p>
                  <p class="font14">
                    <span v-if="item['1'] && item['1'].num > 0 && item.ticketKind !== 12">{{ item['1'].num }} {{ item['1'].num >= 2 ? 'Adults' : 'Adult' }}</span>
                    <span v-else-if="item['1'] && item['1'].num > 0 && item.ticketKind === 12">{{ item['1'].num }} {{ item['1'].num >= 2 ? 'All Ages' : 'All Ages' }}</span>
                    <span v-if="item['1'] && item['2'] && item['1'].num > 0 && item['2'].num > 0">+</span>
                    <span
                      v-if="item['2'] && item['2'].num > 0"
                    >{{ item['2'].num }} {{ item['2'].num >= 2 ? 'Children' : 'Child' }}</span>
                    <span
                      v-if="(item['1'] &&item['1'].num > 0 && item['3'] &&item['3'].num > 0) ||(item['2'] &&item['2'].num > 0 && item['3'] &&item['3'].num > 0)"
                    >+</span>
                    <span v-if="item['3'] && item['3'].num > 0">
                      {{ item["3"].num }}
                      {{ item["3"].num >= 2 ? "Seniors" : "Senior" }}
                    </span>
                    <span v-if="item['4'] && item['4'].num > 0 ">{{ item['4'].num }} All Ages</span>
                  </p>
                  <p>{{ formatDate(item.travelDate) }}</p>
                </div>
                <span
                  v-if="item['1'] && item['2'] && item.ticketKind!==7"
                  class="font_weight_700"
                >¥{{ (item['1'].num * item['1'].price + item['2'].num * item['2'].price + item['3'].num * item['3'].price) || 0 | abs }}</span>
                <span
                  v-if="item['4'] && item['4'].num > 0 "
                  class="font_weight_700"
                >¥{{ (item['4'].num * item['4'].price ) || 0 | abs }}</span>
              </div>
              <div v-if="expressTicket1.length > 0" class="card-body-title">Express Pass</div>
              <div class="ticket-list">
                <div
                  v-for="(item,index) in expressTicket1"
                  :key="index"
                  class="ticket-item flex-space-between"
                >
                  <div class="ticket-item-left">
                    <p class="ticketName">{{ item.officialName }}</p>
                    <p>{{ item.num }} All Ages</p>
                    <p>{{ formatDate(item.travelDate) }}</p>
                    <!-- <p>{{ item.batName }}</p> -->
                    <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                  </div>
                  <span class="font_weight_700">¥{{ item.num * item.price || 0 | abs}}</span>
                </div>
              </div>
            </div>

            <div v-if="braceletTicket.length > 0" class="card-body-title">EXTRAS</div>
            <div class="ticket-list">
              <div
                v-for="(item, index) in braceletTicket"
                :key="index"
                class="ticket-item flex-space-between"
              >
                <div class="ticket-item-left">
                  <p class="ticketName">{{ item.officialName }}</p>
                  <p>{{ item.num }} All Ages</p>
                  <p>{{ formatDate(item.travelDate) }}</p>
                  <!-- <p>{{ item.batName }}</p> -->
                  <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                </div>
                <span class="font_weight_700">¥{{ item.num * item.price || 0 | abs}}</span>
              </div>
            </div>
          </div>
          <!-- <div style="border-top: 1px solid gainsboro;padding:15px;">
            <p class="flex-space-between">
              <span>Subtotal:</span>
              <span>￥7,800.00</span>
            </p>
            <p class="flex-space-between">
              <span>Tax:</span>
              <span>￥7,800.00</span>
            </p>
          </div>-->
          <div class="card-foot">
            <p class="flex-space-between font16">
              <span class="font_weight_700">Total:</span>
              <span class="font_weight_700">¥{{ getTotal() || 0 | abs}}</span>
            </p>
            <p class="font12" style="text-align:right;">(Tax included)</p>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 支付失败弹框 -->
    <transition
      name="fade"
      enter-class="fade-in-enter"
      enter-active-class="fade-in-active"
      leave-class="fade-out-enter"
      leave-active-class="fade-out-active"
    >
      <div v-if="failedTip" class="paymentFailed">
        Payment Failed
        <i @click="failedTip = false" class="el-icon-close" />
      </div>
    </transition>
    <!-- 二维码显示 -->
    <div class="qrcode-box" v-if="codeShow">
      <div id="qrcode"></div>
    </div>

    <!-- 支付弹窗 -->
    <payDialog ref="payDialog" :outerOrderId="outerOrderId"></payDialog>
  </div>
</template>

<script>
import { validEmail } from "@/utils/validate";
import { formatDate, getDateStr } from "@/utils/util";
import { mixin2 } from "@/mixin/index";
import { dateLocalFormat1 } from "@/utils/date";
import {
  order,
  pays,
  getAreaList,
  payPayOrderStatus,
  getMerchant_id,
  getMerchant_id_gmo,
  getOrderTime,
  verifyPayment,
  gmoApplePay
} from "@/api/index.js";
import QRCode from "qrcodejs2";
import {judgeMobile} from '@/utils/util'
import payDialog from "./pay-dialog.vue";
// import { initElements, stripePaymentIntent } from '@/utils/stripe'

export default {
  mixins: [mixin2],
  data() {
    // 校验邮箱
    var validemail = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Please enter emailAddress"));
      } else if (!validEmail(value)) {
        callback(new Error("Incorrect email format"));
      } else {
        callback();
      }
    };
    return {
      form: {
        email: ""
      },
      loading: false,
      country: "", // 国籍名
      area: "", // 日本一级地区名
      nationality: "",
      orderInfo: {},
      tickets: [],
      outerOrderId: "",
      stripe: null,
      failedTip: false,
      expressTicket: [], // 快速券
      expressTicket1: [],
      braceletTicket: [], // 手环
      dialogVisible: false,
      stripeInpShow: false,
      areaList: [], // 国籍列表
      params: "", // 下单参数
      codeShow: false,
      timer: "",
      payType: 0, // 支付方式
      havePay: false,
      payBtnDisable: false,
      paymentData: null,
      showApplePay: false,
      ticketsCopy1: [],
      localCurrentTime: "",
      gmoTokenTest:'',
      localEndTime: "",
      countDown: "",
      countDownStr: "",
      applePayButton:false,
      showCreditCard:false, //是否显示信用卡按钮
      isPhone: judgeMobile()
    };
  },
  beforeRouteLeave(to, from, next) {
    // 清除vtpay
    sessionStorage.removeItem("VTpay");
    next();
  },

  async mounted() {
    // gmo错误提示
    if(this.$route.query.errCode && this.$route.query.errInfo){
         this.$router.replace({ path: "/checkOut", query: { payFail: 1,errCode:this.$route.query.errCode,errInfo:this.$route.query.errInfo } });
    }
    if (this.$route.query.status == 0) {
      this.failedTip = true;
      this.payType = 13;
      // 如果是vt支付失败跳到checkout页面
      if (this.payType === 13) {
        this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
      }
    } else {
      this.failedTip = false;
    }
    setTimeout(() => {
      this.failedTip == true
        ? this.$router.replace({ path: "/checkOut", query: { payFail: 1 } })
        : "";
    }, 200);
    window.ApplePaySession === undefined
      ? (this.showApplePay = false)
      : (this.showApplePay = true);
      console.log(sessionStorage.getItem("VTpay"))
    // 刷新页面时如果是VT支付并且曾调起过支付，就不能再调起支付了
    if (sessionStorage.getItem("VTpay") !== null) {
      this.havePay = true;
    }else{
      this.havePay = false
    }
    console.log(this.havePay);
    this.params = JSON.parse(localStorage.getItem("params"));
    this.form = JSON.parse(localStorage.getItem("peopleInfo"));
    await this.loadData();
    this.dataHandle();
    this.ticketsCopy.forEach(item => {
      if (
        item.ticketKind === 1 ||
        item.ticketKind === 2 ||
        item.ticketKind === 5 ||
        item.ticketKind === 7 ||
        item.ticketKind === 9 ||
        item.ticketKind === 12
      ) {
        this.ticketsCopy1.push(item);
      }
    });
    if (JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList) {
      JSON.parse(
        localStorage.getItem("orderInfo")
      ).orderRequestProductList.forEach(item => {
        if (item.ticketKind === 3) {
          this.expressTicket1.push(item);
        } else if (item.ticketKind === 8) {
          this.braceletTicket.push(item);
        }
      });
    }
    // this.stripe = await window.Stripe(process.env.VUE_APP_STRIPE_PK, {
    //   locale: 'en'
    // })

    this.getAreaList();
    this.initVeriTransPay();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.countDown);
    this.timer = null;
  },
  methods: {
    loadData() {
      if (JSON.parse(localStorage.getItem("orderInfo"))) {
        this.orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
        this.tickets = this.orderInfo.orderRequestProductList;
        getOrderTime(localStorage.getItem("outerOrderId")).then(res => {
        if (res.data.code === 0) {
          if(this.localCurrentTime===''){
            // console.log('订单开始时间',dateLocalFormat1(res.data.data.orderTime));
            // console.log('订单截止',new Date(dateLocalFormat1(res.data.data.orderTime)).getTime() + 60 * 15 * 1000);
            
            let endTime = new Date(dateLocalFormat1(res.data.data.orderTime)).getTime() + 60 * 14 * 1000; //  获取截止时间
            let currentTime = new Date(dateLocalFormat1(res.data.data.currentTime)).getTime(); // 获取服务器当前时间
            // this.payBtnDisable = res.data.data.forbidCreditCardPay === 1 ? true : false // 是否禁用信用卡支付
            this.localCurrentTime =currentTime 
            this.localEndTime = endTime
            if(this.localCurrentTime >= this.localEndTime){
              this.applePayButton=false
              this.countDownStr = "00:00:00";
              console.log("订单超时");
              clearInterval(this.countDown);
              return;
            }
          }
          this.countDown = setInterval(() => {this.orderTime()}, 1000); //倒计时
      } else {
          this.applePayButton=false
          this.countDownStr = "00:00:00";
          console.log("订单超时");
          clearInterval(this.countDown);
          return;
        }
      });
    }
    },
    orderTime() {
      if(this.countDownStr==='00:00:00'){
        getOrderTime(localStorage.getItem("outerOrderId")).then(res => {
        if (res.data.code !== 0)  {
          this.applePayButton=false
          this.countDownStr = "00:00:00";
          console.log("订单超时");
          this.$forceUpdate()
          clearInterval(this.countDown);
          return;
        }
      });
        this.applePayButton=false
        console.log("订单超时",this.applePayButton);
        this.$forceUpdate()
        clearInterval(this.countDown);
        return;
      }
      // 获取下单时间
          this.localCurrentTime =this.localCurrentTime + 1 * 1000
          let times = (this.localEndTime - this.localCurrentTime) / 1000; //距离结束时间的秒数
          //计算分钟数 转化为整数
          let m = parseInt((times / 60) % 60);
          //如果分钟数小于 10，要变成 0 + 数字的形式赋值
          let min = m < 10 ? "0" + m : m;
          //计算描述 转化为整数
          let s = parseInt(times % 60);
          //如果秒钟数小于 10，要变成 0 + 数字的形式赋值
          let second = s < 10 ? "0" + s : s;
          console.log('倒计时打印',m,s);
          
          this.countDownStr = "00:" + min + ":" + second;
          this.applePayButton=true
          // console.log(min + ":" + second);
      
 
    },
    // 苹果按钮超时
    applepayTimeout(){
      this.$message({
          message:"The order has been closed, due to timeout. Please resubmit the order.",
          type: "error",
          duration: 5000
        });
        this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
        return;
    },
    // 下单
    async toPay() {
      let outerOrderId = ""; //订单id
      await order(this.params).then(res => {
        this.loading = true;
        if (res.data.code !== 0) {
          this.loading = false;
          return this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000
          });
        } else {
          this.loading = false;
          outerOrderId = res.data.data;
          localStorage.setItem("outerOrderId", outerOrderId);
          // localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo))
          this.$router.push({ name: "paymentResponce" });
          this.apay(); // 支付
        }
      });
    },
    // 支付
    async apay(type) {
      type ? (this.payType = type) : "";
      if (this.payType === 0) return;
      let isPay = await verifyPayment(localStorage.getItem("outerOrderId"));
      console.log('支付this.havepay',this.havePay)
      // 如果是Vt支付并调起过支付，得重新下单支付
      if (this.havePay) {
        this.$message({
          message: "Please repurchase and place an order to pay",
          type: "error",
          duration: 5000
        });
        this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
        return;
      }
      // 订单是否超时
      if (isPay.data.code !== 0) {
        this.$message({
          message:"The order has been closed, due to timeout. Please resubmit the order.",
          type: "error",
          duration: 5000
        });
        this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
        return;
      }
      // 倒计时结束处理
      if (this.countDownStr === "00:00:00") {
        this.$message({
          message:"The order has been closed, due to timeout. Please resubmit the order.",
          type: "error",
          duration: 5000
        });
        this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
        return;
      }
      if (this.payType === 9) {
        // google统计-Vt支付事件埋点
        this.$gtag.event("checkoutPayOrder", {
          event_category: "checkoutPayOrder",
          event_label: "checkoutPayOrder",
          value: 1000
        });
      }
      this.loading = true;
      const outerOrderId = JSON.parse(localStorage.getItem("outerOrderId"));
      this.tickets.map((item, index) => {
        if (item.num === 0) {
          this.tickets.splice(index, 1);
        }
      });
      console.log(this.isRepeat(this.setPayOrderDesc()));
      if (type == 13) {
        // 处理订单名称
        let newTradeName = "";
        if (this.ticketsCopy1.length !== 0) {
          newTradeName = this.getTicketTypeName(
            this.ticketsCopy1[0].ticketKind
          );
        } else if (this.expressTicket1.length !== 0) {
          newTradeName = this.expressTicket1[0].officialName;
        } else if (this.braceletTicket.length !== 0) {
          newTradeName = this.braceletTicket[0].officialName;
        }

        // 支付按钮10秒内禁止点击
        this.payBtnDisable = true;
        this.timer = setInterval(function() {
          this.payBtnDisable = false;
        }, 10 * 1000);

        this.$refs.payDialog.formLabelAlign.orderID = localStorage.getItem(
          "outerOrderId"
        );
        this.$refs.payDialog.formLabelAlign.money = this.getTotal() / 100;
        this.$refs.payDialog.formLabelAlign.tradeName = newTradeName;
        this.$refs.payDialog.formLabelAlign.jpo = this.payType;
        this.$refs.payDialog.init();
        this.loading = false;

        // google统计-mdk支付弹窗事件埋点
        this.$gtag.event("checkoutPayOrder", {
          event_category: "checkoutPayOrder",
          event_label: "checkoutPayOrder",
          value: 1000
        });

        // VeriTrans支付
        // 记录当前支付类型到local，当页面被刷新后不能再调起支付
        sessionStorage.setItem("VTpay", true);
      } else if(type === 14){
        // todo return
        return
        // 处理订单名称
        let newTradeName = "";
        if (this.ticketsCopy1.length !== 0) {
          newTradeName = this.getTicketTypeName(
            this.ticketsCopy1[0].ticketKind
          );
        } else if (this.expressTicket1.length !== 0) {
          newTradeName = this.expressTicket1[0].officialName;
        } else if (this.braceletTicket.length !== 0) {
          newTradeName = this.braceletTicket[0].officialName;
        }

        // 支付按钮10秒内禁止点击
        this.payBtnDisable = true;
        this.timer = setInterval(function() {
          this.payBtnDisable = false;
        }, 10 * 1000);

        this.$refs.payDialog.formLabelAlign.orderID = localStorage.getItem(
          "outerOrderId"
        );
        this.$refs.payDialog.formLabelAlign.money = this.getTotal() /100 ;
        this.$refs.payDialog.formLabelAlign.tradeName = newTradeName;
        this.$refs.payDialog.formLabelAlign.jpo = this.payType;
        this.$refs.payDialog.init();
        this.loading = false;

        // google统计-mdk支付弹窗事件埋点
        this.$gtag.event("checkoutPayOrder", {
          event_category: "checkoutPayOrder",
          event_label: "checkoutPayOrder",
          value: 1000
        });

        // VeriTrans支付
        // 记录当前支付类型到local，当页面被刷新后不能再调起支付
        sessionStorage.setItem("VTpay", true);
      }else {
        console.log('进来这里',navigator.userAgent)
        let paymentDataObj = this.paymentData;
        let gmoApplePayToken = JSON.parse(paymentDataObj)
        console.log('paymentData',paymentDataObj)
        pays(outerOrderId, {
          orderDesc: encodeURIComponent(this.setPayOrderDesc()).length>60?encodeURIComponent(this.setPayOrderDesc()).substring(0,32):encodeURIComponent(this.setPayOrderDesc()),
          payType: this.payType,
          paymentData:paymentDataObj,
          // paymentData:'{"header":{"ephemeralPublicKey": "testPublicKey","publicKeyHash": "testKeyHash","transactionId": "testTransaction"},"data": "eyJhcHBsaWNhdGlvblByaW1hcnlBY2NvdW50TnVtYmVyIjogIjUxMTExMTExMTExMTExMTEiLCAiYXBwbGljYXRpb25FeHBpcmF0aW9uRGF0ZSI6ICIyMjEyMzEiLCAiY3VycmVuY3lDb2RlIjoiMzkyIiwgInRyYW5zYWN0aW9uQW1vdW50IjoiMTAwMCIsICJjYXJkaG9sZGVyTmFtZSI6ICIiLCAiZGV2aWNlTWFudWZhY3R1cmVySWRlbnRpZmllciI6ICIiLCAicGF5bWVudERhdGFUeXBlIjoiM0RTZWN1cmUiLCAicGF5bWVudERhdGEiOiB7ICAgICJvbmxpbmVQYXltZW50Q3J5cHRvZ3JhbSI6ICJEdW1teUNBVlZBQUFBQUFBQUFBQUFBQUFBQUFBIiwgICAgImVjaUluZGljYXRvciIgOiAiMDUiICB9fQo=","signature": "testSignature","version": "Mock"}',
          userAgent:navigator.userAgent
        }).then(res => {
          // this.loading = true
          if (res.data.code !== 0) {
            this.failedTip = true;
            this.loading = false;
            this.$message({
              message: "Please repurchase and place an order to pay",
              type: "error",
              duration: 5000
            });
            this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
            return;
          } else if (res.data.code === 0) {
            // 支付按钮10秒内禁止点击
            this.payBtnDisable = true;
            this.timer = setInterval(function() {
              this.payBtnDisable = false;
            }, 10 * 1000);
            if (this.payType === 3) {
              // stripe支付
              let id = "";
              if (res.data.data) {
                id = JSON.parse(res.data.data).id;
              }
              this.loading = false;
              localStorage.setItem("payTime", new Date()); // 保存支付时间
              return this.stripe.redirectToCheckout({
                sessionId: id
              });
            } else if (this.payType === 6) {
              // paypal支付
              location.href = res.data.data;
              this.loading = false;
            } else if (this.payType === 7) {
              // paypay支付
              let that = this;
              const data = JSON.parse(res.data.data);
              this.codeShow = true;
              if (data.data.url) {
                this.$nextTick(() => {
                  this.generateQRcode(data.data.url); // paypay扫码支付
                  // location.href = data.data.url // paypay在线支付
                  this.timer = setInterval(function() {
                    that.payPayOrderStatus(outerOrderId);
                  }, 4 * 1000);
                });
              }
              this.loading = false;
            } else if (this.payType === 9) {
              const option = {
                language: "en"
              };
              const payBtn = document.getElementById("payBtn");
              payBtn.addEventListener("click", function() {
                pop.pay(res.data.data, option);
              });
              payBtn.click();
            } else if (this.payType === 11) {
              this.failedTip = false;
              this.$router.push({ path: `/orderComplete?x=${res.data.data}` });
            }else if(this.payType===15){
              console.log('pc Alipay')
              if(res.data.data){
                window.location.href = res.data.data
              }else{
                console.log('没有地址')
              }
            }else if(this.payType===16){
              console.log('mb Alipay')
              if(res.data.data){
                window.location.href = res.data.data
              }else{
                console.log('没有地址')
              }
            }else if(this.payType===17){
              console.log('苹果支付gmo')
              this.failedTip = false;
              this.$router.push({ path: `/orderComplete?x=${res.data.data}` });
            }
          }
        });
      }
    },
    // 初始化initVeriTrans支付环境
    initVeriTransPay() {
      const s = document.createElement("script");
      s.type = "text/JavaScript";
      s.src = "https://pay.veritrans.co.jp/pop/v1/javascripts/pop.js";
      s.setAttribute("data-client-key", "e84c118c-2faa-4eef-a69c-5bc2121f0ada");
      document.body.appendChild(s);
    },
    // 支付时所要传的参数OrderDesc的值
    setPayOrderDesc() {
      // 设置支付信息
      let toStripeInfo = []; // 所购买的所有票的信息
      console.log(this.ticketsCopy1);
      if (this.ticketsCopy1.length > 0) {
        this.ticketsCopy1.map(item => {
          let ticketInfo = ""; // 每张票的信息
          ticketInfo += `【${this.getTicketTypeName(item.ticketKind)}】`; //获取票的前缀
          // ticketInfo += this.conversionPeriod(item)
          if (item["1"].num > 0) {
            ticketInfo += `${item["1"].num}${
              item["1"].num > 1 ? " Adults" : " Adult"
            } `;
          }
          if (item["1"].num > 0 && item["2"].num > 0) {
            ticketInfo += `+ ${item["2"].num}${
              item["2"].num > 1 ? " Children" : " Child"
            }  `;
          } else if (item["2"].num > 0) {
            ticketInfo += `${item["2"].num}${
              item["2"].num > 1 ? " Children" : " Child"
            }  `;
          }
          if (
            (item["1"].num > 0 && item["3"].num > 0) ||
            (item["2"].num > 0 && item["3"].num > 0)
          ) {
            ticketInfo += `+ ${item["3"].num}${
              item["3"].num > 1 ? " Seniors" : " Senior"
            }  `;
          } else if (item["3"].num > 0) {
            ticketInfo += `${item["3"].num}${
              item["3"].num > 1 ? " Seniors" : " Senior"
            }  `;
          }
          if (item["4"] && item["4"].num > 0) {
            ticketInfo += `${item["4"].num} All Ages `;
          }
          toStripeInfo.push(ticketInfo);
        });
      }

      // if (this.expressTicket1.length > 0) {
      //   this.expressTicket1.map(item => {
      //     let ticketInfo = '' // 每张票的信息
      //     ticketInfo += `【${this.getTicketTypeName(item.ticketKind)}】` //获取票的前缀
      //     // ticketInfo += this.conversionPeriod(item)

      //     toStripeInfo.push(ticketInfo)
      //   })
      // }
      if (this.expressTicket1.length > 0) {
        this.expressTicket1.map(item => {
          let ticketInfo = ""; // 每张票的信息
          ticketInfo += `【${item.officialName}】`;
          ticketInfo += `${item.num} All Ages `;
          ticketInfo += `${this.formatDate(item.travelDate)} `;
          toStripeInfo.push(ticketInfo);
        });
      }
      if (this.braceletTicket.length > 0) {
        this.braceletTicket.map(item => {
          let ticketInfo = ""; // 每张票的信息
          ticketInfo += `【${item.officialName}】`;
          ticketInfo += `${item.num} All Ages `;
          ticketInfo += `${this.formatDate(item.travelDate)} `;
          toStripeInfo.push(ticketInfo);
        });
      }
      return toStripeInfo;
    },
    // 苹果支付时所需要的参数
    setApplePayOrderDesc() {
      // 设置支付信息
      let arr = [];
      if (this.ticketsCopy1.length > 0) {
        this.ticketsCopy1.forEach(item => {
          let toStripeInfo = {}; // 所购买的所有票的信息
          let ticketInfo = ""; // 每张票的信息
          let ticketPrice = 0;
          ticketInfo += `【${this.getTicketTypeName(
            item.ticketKind
          )}】  ${this.formatDate(item.travelDate)}`; //获取票的前缀
          // ticketInfo += this.conversionPeriod(item)
          if (item["1"].num > 0) {
            // ticketInfo += `${item['1'].num}${item['1'].num > 1 ? ' Adults' : ' Adult'} `
            ticketPrice += (item["1"].price / 100) * item["1"].num;
          } else if (item["1"].num > 0 && item["2"].num > 0) {
            // ticketInfo += `+ ${item['2'].num}${item['2'].num > 1 ? ' Children' : ' Child'}  `
            ticketPrice +=
              (item["1"].price / 100) * item["1"].num +
              (item["2"].price / 100) * item["2"].num;
          } else if (
            item["1"].num > 0 &&
            item["2"].num > 0 &&
            item["3"].num > 0
          ) {
            // ticketInfo += `+ ${item['3'].num}${item['3'].num > 1 ? ' Seniors' : ' Senior'}  `
            ticketPrice +=
              (item["1"].price / 100) * item["1"].num +
              (item["2"].price / 100) * item["2"].num +
              (item["3"].price / 100) * item["3"].num;
          } else if (item["2"].num > 0) {
            // ticketInfo += `${item['2'].num}${item['2'].num > 1 ? ' Children' : ' Child'}  `
            ticketPrice += (item["2"].price / 100) * item["2"].num;
          } else if (item["3"].num > 0) {
            // ticketInfo += `${item['3'].num}${item['3'].num > 1 ? ' Seniors' : ' Senior'}  `
            ticketPrice += (item["3"].price / 100) * item["3"].num;
          }
          if (item["4"] && item["4"].num > 0) {
            ticketPrice += (item["4"].price / 100) * item["4"].num;
          }
          toStripeInfo.label = ticketInfo;
          toStripeInfo.amount = {
            value: ticketPrice.toString(),
            currency: "JPY"
          };
          arr.push(toStripeInfo);
        });
      }
      if (this.expressTicket1.length > 0) {
        this.expressTicket1.forEach(item => {
          let ticketObj = {};
          ticketObj.label = `【${item.officialName}】${
            item.num
          } All Ages ${this.formatDate(item.travelDate)}`;
          ticketObj.amount = {
            value: ((item.price / 100) * item.num).toString(),
            currency: "JPY"
          };
          arr.push(ticketObj);
        });
      }
      if (this.braceletTicket.length > 0) {
        this.braceletTicket.forEach(item => {
          let ticketObj = {};
          ticketObj.label = `【${item.officialName}】${
            item.num
          } All Ages ${this.formatDate(item.travelDate)}`;

          ticketObj.amount = {
            value: ((item.price / 100) * item.num).toString(),
            currency: "JPY"
          };
          arr.push(ticketObj);
        });
      }
      return arr;
    },
    // 生成二维码
    generateQRcode(codeUrl) {
      const qrcode = new QRCode("qrcode", {
        text: codeUrl,
        width: 300,
        height: 300,
        colorDark: "#000",
        colorLight: "#ffffff"
      });
    },
    payPayOrderStatus(outerOrderId) {
      payPayOrderStatus(outerOrderId).then(res => {
        if (res.data.data.x) {
          clearInterval(this.timer);
          this.$router.push({
            path: "orderComplete",
            query: { x: res.data.data.x }
          });
        }
      });
    },
    async testApple() {
      // this.payType = 11
      if (!PaymentRequest) {
        // this.show
        return;
      }
      let strTotal = this.getTotal() / 100;
      strTotal = strTotal.toString();
      // Define PaymentMethodData
      const paymentMethodData = [
        {
          supportedMethods: "https://apple.com/apple-pay", // 如果以后有更改 去https://applepaydemo.apple.com/ 下面的show source查看更改 一般不需要改
          data: {
            version: 3,
            merchantIdentifier: "merchant.com.tripodeck.usj", // 在 AppStore开发者里面看,一般不用换
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: [
              //支持卡类型
              "amex",
              "discover",
              "masterCard",
              "visa"
            ],
            countryCode: "JP" //国家代号
          }
        }
      ];
      // 订单详情
      const paymentDetails = {
        total: {
          //总价格
          label: "JTRWeb LTD.", //店铺名字
          amount: {
            value: strTotal, //价格
            currency: "JPY" //货币
          }
        },
        displayItems: this.setApplePayOrderDesc(),
        modifiers: [
          {
            supportedMethods: "https://apple.com/apple-pay", // 如果以后有更改 去https://applepaydemo.apple.com/ 下面的show source查看更改 一般不需要改
            data: {
              paymentMethodType: "debit"
            },
            total: {
              label: "JTRWeb LTD.",
              amount: {
                value: strTotal, //价格
                currency: "JPY"
              }
            }
          }
        ]
      };
      //是否需要在Apple Pay填写资料
      const paymentOptions = {
        requestPayerName: false,
        requestBillingAddress: false,
        requestPayerEmail: false, //是否需要填写邮箱
        requestPayerPhone: false, //是否需要填写电话
        requestShipping: false,
        shippingType: "shipping"
      };

      // Create PaymentRequest
      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails,
        paymentOptions
      );
      request.onmerchantvalidation = async event => {
        // 请求后端,获取新的merchant session.
        const merchantSessionPromise = await this.getMerchantSession();

        event.complete(merchantSessionPromise.data.data);
      };

      request.onpaymentmethodchange = event => {
        // Define PaymentDetailsUpdate based on the selected payment method.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingoptionchange = event => {
        // Define PaymentDetailsUpdate based on the selected shipping option.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingaddresschange = event => {
        // Define PaymentDetailsUpdate based on a shipping address change.
        const paymentDetailsUpdate = {
          total: {
            label: "JTRWeb LTD.",
            amount: {
              value: strTotal,
              currency: "JPY"
            }
          }
        };
        event.updateWith(paymentDetailsUpdate);
      };

      let response = await request.show();
      this.paymentData = JSON.stringify(response.details.token.paymentData);
      const status = "success";
      await response.complete(status);
      await this.apay(11);
    },
    // 刷新时清除本地订单id和购物车数据并跳转首页
    removeLocalCache() {
      localStorage.setItem("orderInfo", "");
      localStorage.setItem("outerOrderId", "");
      this.$router.replace({ path: "/" });
    },
    // 设置时间显示的文本
    conversionPeriod(item) {
      var startTime = item.travelDate;
      var endTime = "";
      endTime = getDateStr(startTime, 90);
      startTime = formatDate(startTime);
      endTime = formatDate(endTime);
      return `Valid any same price day from ${startTime} ~ ${endTime} `;
    },
    // 获取国籍（地区）
    getAreaList() {
      const param = {
        parentIds: "100,101,102,103,104,105"
      };
      getAreaList(param).then(res => {
        console.log(res);
        if (res.data.code === 0) {
          this.areaList = res.data.data;
          res.data.data.map(item => {
            if (item.areaId === this.form.country) {
              this.country = item.areaName;
            }
            if (item.areaId === this.form.nationality) {
              this.nationality = item.areaName;
            }
          });
        } else {
          this.areaList = [];
        }
      });
      getAreaList({ parentIds: 100001 }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.length > 0) {
            res.data.data.map(item => {
              if (item.areaId === this.form.area) {
                this.area = item.areaName;
              }
            });
          }
        }
      });
    },
    // 获取总价
    getTotal() {
      let total = 0;
      if (this.ticketsCopy1.length > 0) {
        this.ticketsCopy1.map(item => {
          if (item["1"] && item["2"]) {
            total +=
              item["1"].num * item["1"].price +
              item["2"].num * item["2"].price +
              item["3"].num * item["3"].price;
          }
          if (item["4"]) {
            total += item["4"].num * item["4"].price;
          }
        });
      }
      if (this.expressTicket1.length > 0) {
        this.expressTicket1.map(item => {
          total += item.num * item.price;
        });
      }

      if (this.braceletTicket.length > 0) {
        this.braceletTicket.map(item => {
          total += item.num * item.price;
        });
      }
      return total;
    },

    async onApplePayButtonClickedJapan() {
      // this.payType = 11
      if (!PaymentRequest) {
        // this.show
        return;
      }
      let strTotal = this.getTotal() / 100;
      strTotal = strTotal.toString();
      // Define PaymentMethodData
      const paymentMethodData = [
        {
          supportedMethods: "https://apple.com/apple-pay", // 如果以后有更改 去https://applepaydemo.apple.com/ 下面的show source查看更改 一般不需要改
          data: {
            version: 3,
            merchantIdentifier: "merchant.com.tripodeck.usj", // 在 AppStore开发者里面看,一般不用换
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: [
              //支持卡类型
              "amex",
              "discover",
              "masterCard",
              "visa"
            ],
            countryCode: "JP" //国家代号
          }
        }
      ];
      // 订单详情
      const paymentDetails = {
        total: {
          //总价格
          label: "JTRWeb LTD.", //店铺名字
          amount: {
            value: strTotal, //价格
            currency: "JPY" //货币
          }
        },
        displayItems: this.setApplePayOrderDesc(),
        modifiers: [
          {
            supportedMethods: "https://apple.com/apple-pay", // 如果以后有更改 去https://applepaydemo.apple.com/ 下面的show source查看更改 一般不需要改
            data: {
              paymentMethodType: "debit"
            },
            total: {
              label: "JTRWeb LTD.",
              amount: {
                value: strTotal, //价格
                currency: "JPY"
              }
            }
          }
        ]
      };
      //是否需要在Apple Pay填写资料
      const paymentOptions = {
        requestPayerName: false,
        requestBillingAddress: false,
        requestPayerEmail: false, //是否需要填写邮箱
        requestPayerPhone: false, //是否需要填写电话
        requestShipping: false,
        shippingType: "shipping"
      };

      // Create PaymentRequest
      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails,
        paymentOptions
      );
      request.onmerchantvalidation = async event => {
        // 请求后端,获取新的merchant session.
        const merchantSessionPromise = await this.getMerchantSession();

        event.complete(merchantSessionPromise.data.data);
      };

      request.onpaymentmethodchange = event => {
        // Define PaymentDetailsUpdate based on the selected payment method.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingoptionchange = event => {
        // Define PaymentDetailsUpdate based on the selected shipping option.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingaddresschange = event => {
        // Define PaymentDetailsUpdate based on a shipping address change.
        const paymentDetailsUpdate = {
          total: {
            label: "JTRWeb LTD.",
            amount: {
              value: strTotal,
              currency: "JPY"
            }
          }
        };
        event.updateWith(paymentDetailsUpdate);
      };

      let response = await request.show();
      this.paymentData = JSON.stringify(response.details.token.paymentData);
      // google统计-苹果支付事件埋点
      this.$gtag.event("checkoutApplePay", {
        event_category: "checkoutApplePay",
        event_label: "checkoutApplePay",
        value: 1000
      });
      const status = "success";
      await response.complete(status);
      await this.apay(11);
    },
    // gmo苹果支付
    async onApplePayButtonClickedJapanGMO() {
      console.log('gmo苹果支付')
      // this.payType = 11
      if (!PaymentRequest) {
        // this.show
        return;
      }
      let strTotal = this.getTotal() / 100;
      strTotal = strTotal.toString();
      // Define PaymentMethodData
      const paymentMethodData = [
        {
          supportedMethods: "https://apple.com/apple-pay", // 如果以后有更改 去https://applepaydemo.apple.com/ 下面的show source查看更改 一般不需要改
          data: {
            version: 3,
            merchantIdentifier: "merchant.com.usjticketing.usj", // 在 AppStore开发者里面看,一般不用换
            currencyCode:'JPY',
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: [
              //支持卡类型
              "Amex",
              "masterCard",
              "VISA",
              "JCB",
              'discover'
            ],
            countryCode: "JP" //国家代号
          }
        }
      ];
      // 订单详情
      const paymentDetails = {
        total: {
          //总价格
          label: "JTRWeb LTD.", //店铺名字
          amount: {
            value: strTotal, //价格
            currency: "JPY" //货币
          }
        },
        displayItems: this.setApplePayOrderDesc(),
        modifiers: [
          {
            supportedMethods: "https://apple.com/apple-pay", // 如果以后有更改 去https://applepaydemo.apple.com/ 下面的show source查看更改 一般不需要改
            data: {
              paymentMethodType: "debit"
            },
            total: {
              label: "JTRWeb LTD.",
              amount: {
                value: strTotal, //价格
                currency: "JPY"
              }
            }
          }
        ]
      };
      //是否需要在Apple Pay填写资料
      const paymentOptions = {
        requestPayerName: false,
        requestBillingAddress: false,
        requestPayerEmail: false, //是否需要填写邮箱
        requestPayerPhone: false, //是否需要填写电话
        requestShipping: false,
        shippingType: "shipping"
      };

      // Create PaymentRequest
      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails,
        paymentOptions
      );
      request.onmerchantvalidation = async event => {
        // 请求后端,获取新的merchant session.
        const merchantSessionPromise = await this.getMerchantSessionGmo();

        event.complete(merchantSessionPromise.data.data);
      };

      request.onpaymentmethodchange = event => {
        // Define PaymentDetailsUpdate based on the selected payment method.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingoptionchange = event => {
        // Define PaymentDetailsUpdate based on the selected shipping option.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingaddresschange = event => {
        // Define PaymentDetailsUpdate based on a shipping address change.
        const paymentDetailsUpdate = {
          total: {
            label: "JTRWeb LTD.",
            amount: {
              value: strTotal,
              currency: "JPY"
            }
          }
        };
        event.updateWith(paymentDetailsUpdate);
      };

      let response = await request.show();
      this.paymentData = JSON.stringify(response.details.token.paymentData);
      this.gmoTokenTest = JSON.stringify(response.details.token)
      console.log('苹果response',response)
      // google统计-苹果支付事件埋点
      this.$gtag.event("checkoutApplePay", {
        event_category: "checkoutApplePay",
        event_label: "checkoutApplePay",
        value: 1000
      });
      const status = "success";
      await response.complete(status);
      await this.apay(17);
    },
    getMerchantSession() {
      return new Promise((resolve, reject) => {
        getMerchant_id({
          displayName: "myStore",
          initiative: "web",
          initiativeContext: process.env.VUE_APP_APPLE_KEY,
          merchantIdentifier: "merchant.com.tripodeck.usj"
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getMerchantSessionGmo() {
      return new Promise((resolve, reject) => {
        getMerchant_id_gmo({
          displayName: "myStore",
          initiative: "web",
          initiativeContext: process.env.VUE_APP_APPLE_KEY,
          merchantIdentifier: "merchant.com.usjticketing.usj"
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    splitBatName(str) {
      if(str===undefined) return
      if(str.length===0) return
      let strArr = str.split(")");
      for (let i = 0; i < strArr.length; i++) {
        strArr[i] += ")";
      }
      return strArr.splice(0, strArr.length - 1);
    },
    getTicketTypeName(value) {
      const options = {
        1: "1 Day Studio Pass",
        2: "2 Day Studio Pass",
        5: "1.5 Day Studio Pass",
        7: "NO LIMIT! Countdown 2025 PARTY PASS",
        9: "1 Day Studio Pass + Have Fun in Kansai",
        12: "1 Day Studio Pass + Expo 2025 Osaka Admission ticket",
      };
      return options[value];
    },
    formatDate(val) {
      return formatDate(val);
    },
    isRepeat(arr) {
      if (arr.length > 1) {
        var s = arr.join(",") + ",";
        for (var i = 0; i < arr.length; i++) {
          if (s.replace(arr[i] + ",", "").indexOf(arr[i] + ",") > -1) {
            arr.splice(i, 1);
            return arr;
          }
        }
      } else {
        return arr;
      }
    }
  },
  components: {
    payDialog
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  .main {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .main_left {
      padding-right: 0.9375rem;
    }
  }
  .row {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
  }
}
.aside {
  margin-top: 90px;
  padding-left: 0.9375rem;
  .el-card {
    width: 360px;
    border-radius: 12px;
  }
}
.card-body-title {
  background-color: #f9f9f9;
  padding-left: 10px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  padding: 13px 16px 11px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.ticket-item {
  padding: 16px;
}
.ticketName {
  font-size: 14px;
  line-height: 1.25;
  font-weight: 700;
}
.ticket-item-left {
  max-width: 240px;
  margin-right: 20px;
}
.card-foot {
  padding: 16px 15px 28px;
}
.el-icon-arrow-left {
  font-weight: 700;
  font-size: 25px;
  position: relative;
  top: -6px;
  &::before {
    vertical-align: middle;
  }
}
.base {
  margin-top: 16px;
  padding: 15px 20px;
  background-color: #e0f0ff;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 16px;
  color: #333;
  font-size: 16px;
}
.paymentFailed {
  position: absolute;
  top: 144px;
  text-align: center;
  background-color: #e61b23;
  width: 100%;
  height: 100px;
  line-height: 100px;
  color: white;
  font-size: 20px;
}
.el-icon-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  font-size: 27px;
  font-weight: 500;
}
.fade-in-active,
.fade-out-active {
  transition: all 0.5s ease;
}
.fade-in-enter,
.fade-out-active {
  opacity: 0;
}
.continue-btn {
  border-radius: 13px;
  background-color: #036ce2;
}
.pay-btn {
  border-radius: 13px;
  background-color: #52a41d;
}
.grayBackground {
  background-color: #dbdbdb;
}
.peopleInfoForm {
  font-size: 16px;
  line-height: 35px;
  p span:first-child {
    font-weight: 700;
  }
}
.edit {
  cursor: pointer;
}
.form {
  width: 300px;
  #card-element {
    box-shadow: 0 1px 1px 1px gray;
    .__PrivateStripeElement {
      height: 30px !important;
      line-height: 30px !important;
    }
  }
  #paySubmit {
    margin-top: 15px;
    font-weight: bold;
    background-color: #556cd6;
    color: white;
    height: 30px;
    line-height: 30px;
    border: 1px solid;
    border-radius: 5px;
    width: 300px;
    text-align: center;
  }
}
.el-icon-check {
  margin-right: 8px;
  color: #52a41d;
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
    .main {
      width: 100%;
      padding: 0 0.9375rem;
      box-sizing: border-box;
    }
  }
}
@media (max-width: 750px) {
  .main {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
  }
  .aside {
    display: none;
  }
}
.qrcode-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 150px;
  z-index: 10;
}
</style>
<style scoped>
::v-deep .el-card__header {
  text-align: center;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .card-foot {
  border-top: 1px solid gainsboro;
}
::v-deep .el-input__inner {
  border-radius: 13px;
  border-color: black;
}
apple-pay-button {
  --apple-pay-button-width: 120px;
  --apple-pay-button-height: 40px;
  --apple-pay-button-border-radius: 12px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
  margin-top: 15px;
  margin-left: 15px;
  display: block;
}
.apple-pay-btn-wrapper .apple-pay-btn > span > svg {
  height: 50% !important;
}
</style>
